import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { graphql, Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner from 'components/Banner';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { getYoutubeId } from '../../utils/functions';

import 'styles/main.scss';
import './HomePage.scss';

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: {
      urls,
      seo,
      youtubeLinks,
      articleText1,
      articleText2,
      productText1,
      productText2,
      productLink,
      recipeText1,
      recipeText2,
      recipeLink,
      headband,
      imageDesktop,
      imageMobile,
      bannerSlider,
    },
    allArticleList: { nodes: articleLists },
    header: { logo },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="homepage">
        <div className="homepage__banner">
          {bannerSlider ? <Banner {...{ bannerSlider }} /> : null}
        </div>
        <div className="homepage__video px-2">
          <div className="homepage__video--wrapper pb-4">
            {youtubeLinks ? (
              <Carousel infiniteLoop showArrows showStatus={false} showThumbs={false}>
                {youtubeLinks.map(({ url, name }) => (
                  <YouTubeLazyLoaded key={name} videoId={getYoutubeId(url)} videoName={name} />
                ))}
              </Carousel>
            ) : null}
          </div>
        </div>
        <div className="homepage__academy">
          <h2 className="text-center">{articleText1}</h2>
          <div className="container-fluid">
            <div className="row">
              <div className="col d-none homepage__academy__item-circle d-md-flex justify-content-center align-items-center mb-4">
                <div className="homepage__academy__circle">
                  <DangerouslySetInnerHtml
                    html={articleText2}
                    element="span"
                    className="text-center"
                  />
                </div>
              </div>
              {articleLists.map(({ id, title, url, tags, imageHome, iconHome }) => (
                <div className="col d-flex align-items-center mb-4" key={id}>
                  <div className={`homepage__academy__item-${tags.name}`}>
                    <div className="homepage__academy__image">
                      <Link to={url}>
                        <Image imageData={imageHome} alt={imageHome.altText} />
                      </Link>
                    </div>
                    <div className="homepage__academy__text text-center py-1 px-2 d-flex align-items-center">
                      <Image imageData={iconHome} alt={iconHome.altText} />
                      <div className="w-100">
                        <Link to={url}>{title}</Link>
                      </div>
                      <i className="fas fa-chevron-right text-white" />
                    </div>
                  </div>
                </div>
              ))}
              <div className="col d-block d-md-none homepage__academy__item-circle d-flex justify-content-center align-items-center mb-4">
                <div className="homepage__academy__circle">
                  <DangerouslySetInnerHtml
                    html={articleText2}
                    element="span"
                    className="text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage__products">
          <div className="container-fluid px-0">
            {imageDesktop ? (
              <Image
                className="d-none d-md-block"
                imageData={imageDesktop}
                alt={imageDesktop.name}
              />
            ) : null}
            {imageMobile ? (
              <Image
                className="d-md-none w-100 pt-5"
                imageData={imageMobile}
                alt={imageMobile.name}
              />
            ) : null}
            <div className="row homepage__products__content">
              <div className="col offset-md-7 text-center homepage__products__info">
                <h2 className="homepage__products__title text-uppercase">{productText1}</h2>
                <p className="homepage__products__subtitle">{productText2}</p>
                <div>
                  <Link to={productLink.url} className="w-100 d-block py-2">
                    {productLink.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage__recipes d-flex align-items-md-center">
          <div className="container-fluid">
            <div className="row pt-5 mt-5 pt-md-0 mt-md-0">
              <div className="col text-center text-white mx-auto">
                <h2 className="pb-2">{recipeText1}</h2>
                <div>
                  <Image imageData={logo} alt={logo.altText} />
                </div>
                <p className="pt-3">{recipeText2}</p>
                <div className="mb-4">
                  <Link to={recipeLink.url} className="d-block py-2">
                    {recipeLink.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      bannerSlider {
        bannerText1
        bannerText2
        bannerDesktop {
          childImageSharp {
            gatsbyImageData
          }
        }
        bannerMobile {
          childImageSharp {
            gatsbyImageData
          }
        }
        link {
          name
          url
        }
      }
      urls {
        lang
        href
      }
      headband {
        id
      }
      seo {
        ...SEOStructureFragment
      }
      youtubeLinks {
        name
        url
      }
      video
      articleText1
      articleText2
      productText1
      productText2
      productLink {
        name
        url
      }
      recipeText1
      recipeText2
      recipeLink {
        name
        url
      }
      imageDesktop {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
      imageMobile {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allArticleList {
      nodes {
        id
        title
        url
        tags {
          name
          id
        }
        imageHome {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
        }
        iconHome {
          altText
          fallbackUrl
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 35)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 35)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 35)
            }
          }
        }
      }
    }
    header {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 300)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 300)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 300)
          }
        }
      }
    }
  }
`;

export default HomePage;
